<template>
  <b-card>
    <b-row class="match-height p-3">
      <iframe title="Ecoopsos Auditoria" width="100%" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiNzY0ZDc2ZWYtM2RkMS00ZjcyLWFjM2UtOGMzZjQyNDE0NDNiIiwidCI6ImUyZDRlYTMwLTdlNzUtNDJkNi04ZGIxLTcwNTQ4ZDJmOGMzYSIsImMiOjR9" frameborder="0" allowFullScreen="true"></iframe>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardText, BCardTitle, BLink, BRow, BCol, BCardHeader, BCardBody } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    BCardBody
  },
  data() {
    return {
      
    }
  },
  created() {

  },
  methods: {
    
  }
}
</script>

<style lang="css" scoped>
  .powerbi-module {
    width: 100%;
  }
</style>
